body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 576px) {
    .ms-page-informative article {
        padding-left: 5%;
        padding-right: 5%;
    }
}

.ms-page-informative article .title-block {
    padding-top: 14px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.color-green {
    color: #34a02c;
}

.color-green-li::marker {
    color: #34a02c;
    font-weight: bold;
}

.color-orange {
    color: #ff6700;
}

.color-orange-li::marker {
    color: #ff6700;
    font-weight: bold;
}

.font-weight-bold {
    font-weight: bold;
}

.justify {
    text-align: justify;
}

.links-breadcrumb {
    text-decoration: none;
    color: #354243;
}

.breadcrumb-item-fm {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
}

.breadcrumb-item-fm + .breadcrumb-item-fm::before {
    content: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4IiBoZWlnaHQ9IjgiPjxwYXRoIGQ9Ik0yLjUgMEwxIDEuNSAzLjUgNCAxIDYuNSAyLjUgOGw0LTQtNC00eiIgZmlsbD0iY3VycmVudENvbG9yIi8+PC9zdmc+);;
}

.container-login {
    width: 60%;
}

.lista-navbar {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
}

.lista-navbar .item-navbar {
    width: 50%;
    border-bottom: 4px solid #ccc;
    height: 52px;
}

.lista-navbar .item-navbar.active {
    border-bottom: 4px solid #ff6700;
    color: #ff6700;
}

.lista-navbar .item-navbar a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 15px;
    line-height: 1;
    user-select: none;
    cursor: pointer;
}

.form-login {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0 36px;
}

.item-form {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding: 0 4px;
    border: none;
    outline: none;
}

.enlace-text, .enlace-text:hover {
    color: #666;
    text-decoration: underline;
    padding: 0 0 32px;
    font-weight: 600;
    font-size: 14px;
}

.item-form.full-width {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
}

.btn-form1 {
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    background: #ff6700;
    width: 100%;
    min-height: 32px;
    border-radius: 3px;
    cursor: pointer;
    user-select: none;
    border: 1px solid transparent;
}

.container-social {
    display: flex;
    padding: 24px 0 16px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    margin: 0 -4px;
}

.content-social {
    width: 50%;
    padding: 0 4px;
}

.btn-social {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    user-select: none;
    cursor: pointer;
    border-radius: 4px;
    text-decoration: none;
}

.btn-social i {
    color: #fff;
    font-size: 20px;
    margin: 0 10px 0 0;
}

.btn-social span {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 16px;
    color: #fff;
}

.btn-social.google-social {
    background-color: #cd3919;
    border-color: #cd3919;
}

.btn-social.facebook-social {
    background-color: #0062b3;
    border-color: #0062b3;
}

.icon-eye {
    margin-left: -30px;
    cursor: pointer;
}

.fm-form-input {
    outline: none;
}

.fm-form-input.enabled {
    background-color: #ebffde;
    border-color: #45A009;
    color: #45A009;
}

.form-recover {
    flex-wrap: wrap;
    padding: 20px 0 36px;
}

.form-recover > .item-form {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
}

.check-label > a {
    align-content: center;
    color: #ff6700;
    text-decoration: none;
}

.check-label > span {
    margin-left: 5px;
    margin-right: 5px;
}

.form-check-input:checked {
    background-color: #ff6700;
    border-color: #ff6700;
}

.btn-form1 i {
    margin: 0 5px 0 0;
    font-size: 16px;
}

@media only screen and (max-width: 576px) {
    .container-login {
        width: 100%;
        padding: 16px 8px;
        background: #fff;
    }

    .item-form {
        width: 100%;
    }

    .check-label > a, .check-label > span {
        font-size: 12px;
    }
}
